<template>
  <div>
    <!--begin::Table-->
    <!-- DATA MOIS EN COURS -->
    <vue-good-table
      mode="remote"
      styleClass="custom-table condensed"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: false }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
      @page-change="onPageChange"
      @sort-change="onSortChange"
      @column-filter="onColumnFilter"
      @per-page-change="onPerPageChange"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-column="props">
        <span class="small-col-header">
          {{ props.column.label }}
        </span>
      </template>
      <template #table-row="props">
        <!-- Column: Common -->
        <span>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";

export default {
  data() {
    return {
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        {
          label: "Clinics",
          field: "clinics",
          width: "20%",
        },
        {
          label: "Consultations",
          field: "consultations",
        },
        {
          label: "Simulations",
          field: "simulations",
        },
        {
          label: "Operations",
          field: "operations",
        },
        {
          label: "Pigments",
          field: "pigments",
        },
        {
          label: "Total",
          field: "total",
        },
        {
          label: "Billed (excl. VAT)",
          field: "billed",
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
      },
    };
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      console.log("onPageChange", params);
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (["pigments", "revenue", "revenuePercentage"].includes(e)) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: "clinic pigments revenue",
      };

      ApiService.query("/", { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-col-header {
  font-size: 12px;
}
</style>