<template>
  <div>
    <!--begin::Table-->
    <!--
      mode="remote"
      :pagination-options="{ enabled: false }"
      :sort-options="{ enabled: false }"
      :fixed-header="true"
    -->
    <vue-good-table
      styleClass="custom-table condensed"
      :total-rows="rows.length"
      :is-loading="isTableLoading"
      :rows="rows"
      :columns="columns"
      :group-options="{
        enabled: true,
        headerPosition: 'bottom'
      }"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-column="props">
        <span class="small-col-header">
          {{ props.column.label }}
        </span>
      </template>
      <template #table-row="props">
        <!-- Column: Common -->
        <span>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";

export default {
    props: {
        prospectsType: {
            type: String,
            required: true,
        },
        totalVisitsLast6Months: {
            type: Number,
            required: true,
        },
    },
  data() {
    return {
      isTableLoading: true,
      columns: [],
      rows: [],
    };
  },
  created() {
    this.fetchItemsFromApi();
  },
  methods: {
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const from = new Date(), to = new Date();
      from.setMonth(from.getMonth() - 6);
      to.setDate(1); // going to 1st of the month
      to.setHours(-1); // going to last hour before this date even started

      ApiService
        .query(
          `/statistics/${this.prospectsType}-prospects`,
          {
            params: {
              from: `${from.toISOString().substr(0, 7)}-01`,
              to: to.toISOString().substr(0, 10),
              country: "ALL",
            },
          }
        )
        .then(({ data }) => {
          this.columns = [
            {
              label: "Question",
              field: "question",
              width: "25%",
            },
            ...data[0].data.map(month => ({
              label: month.x.substring(0, 7),
              field: month.x,
            })),
            {
              label: "Total",
              field: "total",
            },
            {
              label: `% of visits (/${this.totalVisitsLast6Months})`,
              field: "visitsRatio",
              formatFn: e => `${e.toFixed(2)}%`,
            }
          ];

          const totalRow = { question: "Total", total: 0, visitsRatio: 0, children: [] };
          const questionsForGrandTotalOnly = [];

          for (const question of data) {
            // Exclude some rows from Total and display them only in Grand Total
            if (["Simulation", "Retouch"].includes(question.name)) {
              questionsForGrandTotalOnly.push(question);
              continue;
            }

            const row = { question: question.name, total: 0, visitsRatio: 0 };
            for (const monthData of question.data) {
              row[monthData.x] = monthData.y;
              row.total += monthData.y;

              totalRow[monthData.x] = totalRow[monthData.x]
                ? totalRow[monthData.x] + Number(monthData.y)
                : Number(monthData.y);
              totalRow.total += Number(monthData.y);
            }
            
            row.visitsRatio = row.total / this.totalVisitsLast6Months * 100;
            totalRow.visitsRatio += row.visitsRatio;
            totalRow.children.push(row);
          }

          this.rows.push(totalRow);
          
          // Handle rows that are only taken into account for grand total
          if (questionsForGrandTotalOnly.length) {
            const grandTotalRow = {...totalRow, question: "Grand Total", children: []}; // clone
            for (const question of questionsForGrandTotalOnly) {
              const row = { question: question.name, total: 0, visitsRatio: 0 };
              for (const monthData of question.data) {
                row[monthData.x] = monthData.y;
                row.total += monthData.y;

                grandTotalRow[monthData.x] = grandTotalRow[monthData.x]
                  ? grandTotalRow[monthData.x] + Number(monthData.y)
                  : Number(monthData.y);
                grandTotalRow.total += Number(monthData.y);
              }

              row.visitsRatio = row.total / this.totalVisitsLast6Months * 100;
              grandTotalRow.visitsRatio += row.visitsRatio;
              grandTotalRow.children.push(row);
            }

            this.rows.push(grandTotalRow);
          }

          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-col-header {
  font-size: 12px;
}
</style>
<style lang="scss">
tbody {
  border-bottom-width: 2px;
}
</style>