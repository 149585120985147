<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8">
    <h1 class="mb-0">Hello, {{ loggedUser }}!</h1>
    <span class="text-muted fw-bold fs-7 mt-1">Get an overview of Neoris Key Performance Indicators.</span>
    <div class="row mt-10">
      <div class="col-12 col-xl-2">
        <ul
          class="
            nav nav-tabs nav-pills nav-justified
            flex-row
            border-0
            flex-md-column
            me-5
            mb-3 mb-md-0
            fs-6
          "
        >
          <li class="nav-item me-0 mb-md-2">
            <a
              class="nav-link btn btn-flex btn-active-info active"
              data-bs-toggle="tab"
              href="#sea-reports"
            >
              <i class="bi-clipboard-data fs-4 me-3"></i>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">SEA Reports</span>
              </span>
            </a>
          </li>
          <li class="nav-item me-0 mb-md-2">
            <a
              class="nav-link btn btn-flex btn-active-info"
              data-bs-toggle="tab"
              href="#stats-website"
            >
              <i class="bi-layout-text-window-reverse fs-4 me-3"></i>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Website</span>
              </span>
            </a>
          </li>
          <li class="nav-item me-0 mb-md-2">
            <a
              class="nav-link btn btn-flex btn-active-info"
              data-bs-toggle="tab"
              href="#stats-clinics"
            >
              <i class="bi-building fs-4 me-3" />
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Clinics</span>
              </span>
            </a>
          </li>
          <li class="nav-item me-0 mb-md-2">
            <a
              class="nav-link btn btn-flex btn-active-info"
              data-bs-toggle="tab"
              href="#targets"
            >
              <i class="bi-bullseye fs-4 me-3" />
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Targets</span>
              </span>
            </a>
          </li>
          <!-- <li class="nav-item me-0 mb-md-2">
            <a
              class="nav-link btn btn-flex btn-active-info"
              data-bs-toggle="tab"
              href="#stats-sales"
            >
              <i class="bi-currency-dollar fs-4 me-3" />
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Sales</span>
              </span>
            </a>
          </li> -->
        </ul>
      </div>
      <div class="col-12 col-xl-10 tab-content">
        <div
          class="row tab-pane fade show active"
          id="sea-reports"
          role="tabpanel"
        >
          <iframe width="600" height="750" src="https://lookerstudio.google.com/embed/reporting/4c84ae81-9681-4f41-9a88-f3cf240248d8/page/CIxcD" frameborder="0" style="border:0" allowfullscreen></iframe>
          <!-- <hr class="mt-2 mb-4" />
          <iframe width="600" height="1430" src="https://datastudio.google.com/embed/reporting/00896a65-4439-4245-91dd-7b1d5f03759a/page/oVL0B" frameborder="0" style="border:0" allowfullscreen></iframe> -->
        </div>
        <div
          class="row tab-pane fade"
          id="stats-website"
          role="tabpanel"
        >
          <template v-if="false">
            <div v-if="initTargetsLoaded" class="col-12 mb-10">
              <TrafficWidget :objective="targets.yearlyVisitsObjective" />
            </div>
            <div class="col-12 mb-10">
              <TrafficTable @totalVisitsLast6Months="onTotalVisitsLast6Months" />
            </div>
          </template>
          <div v-if="false" class="col-12 mb-10">
            <h3>
              Traffic &amp; prospects table (last 6 months)
            </h3>
            <TrafficAndProspectsTable />
          </div>
          <div class="col-12 mb-10">
            <ProspectsWidget prospects-type="cold" />
          </div>
          <div class="col-12 mb-10">
            <ProspectsTable
              v-if="totalVisitsLast6Months"
              :totalVisitsLast6Months="totalVisitsLast6Months"
              prospects-type="cold"
            />
          </div>   
          <div class="col-12 mb-10">
            <ProspectsWidget prospects-type="hot" />
          </div>
          <div class="col-12 mb-10">
            <ProspectsTable
              v-if="totalVisitsLast6Months"
              :totalVisitsLast6Months="totalVisitsLast6Months"
              prospects-type="hot"
            />
          </div>   
          <div class="col-12">
            <ProspectsWidget prospects-type="ratio" />
          </div>
        </div>
        <div class="row tab-pane fade" id="stats-clinics" role="tabpanel">
          <div class="col-12">
            <SalesWidget class="mb-10" revenue-type="clinics" />
            
            <!-- Displays total monthly revenue for each clinic within the last 6 months -->
            <RevenueTable class="mb-10" />
            
            <!-- Displays current month's revenues for each clinic splitted by revenue type (hot prospect, marketplace) -->
            <MonthlyRevenueTable v-if="false" class="mb-10" />
          </div>
        </div>
        <!-- <div class="row tab-pane fade" id="stats-sales" role="tabpanel">
          <div class="col-12">
            <SalesWidget class="mb-10" revenue-type="sales" />
            <SalesTable></SalesTable>
          </div>
        </div> -->
        <!--begin::Targets Tab-->
        <div class="row tab-pane fade" id="targets" role="tabpanel">
          <div class="col-12 mb-10">
            <div class="card">
              <form class="form" @submit.prevent="saveChanges()">
                <div class="card-body border-top">
                  <div class="row">
                    <label class="col-lg-3 col-form-label required fw-bold fs-6">
                      Yearly visits objective
                    </label>

                    <div class="col-lg-3">
                      <input
                        v-model="targets.yearlyVisitsObjective"
                        type="number"
                        class="form-control"
                        placeholder="500000"
                        required
                      />
                      <small>
                        = {{ Math.ceil(targets.yearlyVisitsObjective / 365) }} daily visits
                        = {{ Math.ceil(targets.yearlyVisitsObjective / 12) }} monthly visits
                      </small>
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                  <button :data-kt-indicator="targetsLoading ? 'on' : null" type="submit" class="btn btn-primary" :disabled="targetsLoading">
                    <span v-if="!targetsLoading" class="indicator-label"> Save Changes </span>
                    <span v-else class="indicator-progress">
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--end::Targets Tab-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script>
import TrafficWidget from "@/components/admin-dashboard/TrafficWidget";
import TrafficTable from "@/components/admin-dashboard/TrafficTable";
import TrafficAndProspectsTable from "@/components/admin-dashboard/TrafficAndProspectsTable";
import ProspectsWidget from "@/components/admin-dashboard/ProspectsWidget";
import ProspectsTable from "@/components/admin-dashboard/ProspectsTable";
import RevenueTable from "@/components/admin-dashboard/RevenueTable";
// import SalesTable from "@/components/admin-dashboard/SalesTable";
import SalesWidget from "@/components/admin-dashboard/SalesWidget";
import MonthlyRevenueTable from "@/components/admin-dashboard/MonthlyRevenueTable";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

export default {
  components: {
    TrafficWidget,
    TrafficTable,
    TrafficAndProspectsTable,
    ProspectsWidget,
    ProspectsTable,
    RevenueTable,
    // SalesTable,
    SalesWidget,
    MonthlyRevenueTable,
  },
  data() {
    return {
      totalVisitsLast6Months: null,
      initTargetsLoaded: false,
      targetsLoading: false,
      targets: {
        yearlyVisitsObjective: null,
      },
    };
  },
  mounted() {
    setCurrentPageTitle("Dashboard");
    ApiService.get('/settings/YEARLY_VISITS_OBJECTIVE').then(({ data }) => {
      if (data.value) this.targets.yearlyVisitsObjective = data.value;
      this.initTargetsLoaded = true;
    });
  },
  computed: {
    loggedUser() {
      const user = JwtService.getUser();
      return `${user.firstname}`;
    },
  },
  methods: {
    onTotalVisitsLast6Months(visits) {
      this.totalVisitsLast6Months = Number(visits);
    },
    saveChanges() {
      this.targetsLoading = true;

      ApiService
        .update(
          '/settings',
          'YEARLY_VISITS_OBJECTIVE',
          { value: this.targets.yearlyVisitsObjective },
          { responseToast: { text: "Target successfully updated" }}
        )
        .then(() => this.targetsLoading = false)
        .catch(err => {
          console.error(err);
          this.targetsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-link.active {
  background-color: lighten(#dddee4, 4%) !important;
}
.menu-link:hover {
  background-color: lighten(#dddee4, 9%) !important;
}
.email-item:hover {
  background-color: rgba(221, 222, 228, 0.1);
}
</style>