<template>
  <div>
    <!--begin::Table-->
    <vue-good-table
      mode="remote"
      styleClass="custom-table condensed last-row-bold"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: false }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-column="props">
        <span class="small-col-header">
          {{ props.column.label }}
        </span>
      </template>
      <template #table-row="props">
        <!-- Column: Common -->
        <span>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";

export default {
  emits: ['totalVisitsLast6Months'],
  data() {
    return {
      isTableLoading: false,
      totalRecords: 0,
      columns: [],
      rows: [],
      serverParams: {
        columnFilters: {},
      },
    };
  },
  created() {
    this.fetchItemsFromApi();
  },
  methods: {
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const from = new Date(), to = new Date();
      from.setMonth(from.getMonth() - 6);
      to.setDate(1); // going to 1st of the month
      to.setHours(-1); // going to last hour before this date even started

      ApiService
        .query(
          `/statistics/visits`,
          {
            params: {
              from: `${from.toISOString().substr(0, 7)}-01`,
              to: to.toISOString().substr(0, 10),
              country: "ALL",
              countryDimension: 1,
            },
          }
        )
        .then(({ data }) => {
          const monthsList = [...new Set(data.data.map(month => month.x))];
          monthsList.sort();

          this.columns = [
            { label: "Country (≥5% traffic)", field: "country", width: "25%" },
            ...monthsList.map(month => ({ label: month, field: month })),
            { label: "Total", field: "total",  },
            { label: "Ratio", field: "percentage", formatFn: e => `${e.toFixed(2)}%` },
          ];

          let grandTotal = 0;
          data.total.forEach(countryTotal => grandTotal += countryTotal.y);
          this.$emit('totalVisitsLast6Months', grandTotal);
          
          this.rows = [{ country: "Others", total: 0, percentage: 0 }];
          const totalRow = { country: "All", total: grandTotal, percentage: 100 };

          for (const countryTotal of data.total) { // 12 most popular countries
            if (countryTotal.y >= grandTotal * 0.05) { // ≥ 5% of total
              this.rows.push({
                country: countryTotal.country,
                total: countryTotal.y,
                percentage: countryTotal.y / grandTotal * 100,
              });
            } else {
              this.rows[0].total += countryTotal.y;
            }
          }
          
          this.rows[0].percentage = this.rows[0].total / grandTotal * 100;

          for (const countryMonth of data.data) {
            const el = this.rows.find(e => e.country == countryMonth.country)
              || this.rows[0]; // this.rows[0] -> total row

            el[countryMonth.x] = el[countryMonth.x]
              ? Number(el[countryMonth.x]) + Number(countryMonth.y)
              : countryMonth.y;

            totalRow[countryMonth.x] = totalRow[countryMonth.x]
              ? totalRow[countryMonth.x] + Number(countryMonth.y)
              : Number(countryMonth.y);
          }

          this.rows.push(this.rows.shift(), totalRow);
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-col-header {
  font-size: 12px;
}
</style>
<style lang="scss">
.last-row-bold tbody tr:last-child {
  font-weight: 900;
}
</style>