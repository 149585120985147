<template>
  <div>
    <!--begin::Table-->
    <vue-good-table
      styleClass="custom-table condensed"
      :total-rows="rows.length"
      :is-loading="isTableLoading"
      :rows="rows"
      :columns="columns"
      :group-options="{
        enabled: true,
        headerPosition: 'bottom'
      }"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-column="props">
        <span class="small-col-header">
          {{ props.column.label }}
        </span>
      </template>
      <template #table-row="props">
        <!-- Column: Common -->
        <span>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";

export default {
  data() {
    return {
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        // {
        //   label: "YTD Revenue",
        //   field: "ytdRevenue",
        // },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
      },
    };
  },
  created() {
    this.fetchItemsFromApi();
  },
  methods: {
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const from = new Date(), to = new Date();
      from.setMonth(from.getMonth() - 6);
      to.setDate(1); // going to 1st of the month
      to.setHours(-1); // going to last hour before this date even started

      const getApiParams = {
        from: `${from.toISOString().substr(0, 7)}-01`,
        to: to.toISOString().substr(0, 10),
      };

      ApiService.query("/statistics/clinics-revenue", { params: getApiParams })
        .then(({ data }) => {
          this.columns = [
            {
              label: "Clinic",
              field: "clinic",
              width: "25%",
            },
            ...data[0].data.map(month => ({
              label: month.x.substring(0, 7),
              field: month.x,
            })),
            {
              label: "Total",
              field: "total",
            },
          ];

          const totalRow = { clinic: "Total", total: 0, children: [] };

          for (const clinic of data) {

            const row = { clinic: clinic.name, total: 0 };
            for (const monthData of clinic.data) {
              row[monthData.x] = monthData.y;
              row.total += monthData.y;

              totalRow[monthData.x] = totalRow[monthData.x]
                ? totalRow[monthData.x] + Number(monthData.y)
                : Number(monthData.y);
              totalRow.total += Number(monthData.y);
            }
            
            totalRow.children.push(row);
          }

          this.rows.push(totalRow);
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-col-header {
  font-size: 12px;
}
</style>