<template>
  <!--begin::Charts Widget 1-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <!--begin::Title-->
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">
          <template v-if="prospectsType === 'hot'">
            <i class="bi bi-sun fs-4 me-2"></i>Hot Prospects
          </template>
          <template v-else-if="prospectsType === 'cold'">
            <i class="bi bi-snow fs-4 me-2"></i>Cold Prospects
          </template>
          <template v-else>
            <i class="bi bi-sun fs-4 me-2"></i>Prospects Ratio
          </template>
        </span>

         <span
          v-if="prospectsType === 'ratio'"
          class="text-muted fw-bold fs-7"
        >
          Distribution of new hot/cold prospects
        </span>
         <span v-else class="text-muted fw-bold fs-7">Total questions asked by {{ prospectsType }} prospects</span>
      </h3>
      <!--end::Title-->

      <!--begin::Toolbar-->
      <div class="card-toolbar">
        <div class="card-toolbar">
          <div class="d-flex flex-row align-items-center">
            <select
              class="form-select form-select-sm me-2"
              aria-label="Select example"
              :value="selectedPeriod"
              @change="e => updatePeriod(e.target.value)"
            >
              <option value="7d">Last 7 days</option>
              <option value="30d">Last 30 days</option>
              <option value="12m">Last 12 months</option>
              <option value="ytd">YTD</option>
              <option value="all">All time</option>
              <!-- <option value="custom">Custom</option> -->
            </select>
            <select
              class="form-select form-select-sm"
              aria-label="Country"
              :value="selectedCountry"
              @change="e => updateCountry(e.target.value)"
            >
              <option value="ALL">All countries</option>
              <option value="FR">FR / BE / LU</option>
              <option value="DE">DE / AT</option>
              <option value="IT">IT</option>
              <option value="ES">ES</option>
              <option value="GB">GB</option>
              <option value="USA">US</option>
              <option value="CH">CH</option>
              <option value="OTHERS">Others</option>
            </select>
          </div>
        </div>
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart type="area" :options="options" :series="series" height="296"></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Charts Widget 1-->
</template>
<script>
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default {
  props: {
    prospectsType: {
      type: String,
      default: 'cold',
    }
  },
  data() {
    return {
      labelColor: getCSSVariableValue("--bs-gray-500"),
      borderColor: getCSSVariableValue("--bs-gray-200"),
      baseColor: getCSSVariableValue("--bs-primary"),
      secondaryColor: getCSSVariableValue("--bs-gray-300"),

      isLoading: false,
      selectedPeriod: "7d",
      selectedCountry: "ALL",
      from: null,
      to: null,
      options: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: true,
        },
        xaxis: {
          // type: 'numeric',
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: this.baseColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: e => this.prospectsType === 'ratio' ? `${e} prospects` : `${e} times asked`
          },
      //     custom: ({series, seriesIndex, dataPointIndex, w}) => {
      //       console.log(seriesIndex)
      //       console.log(series)
      //       return '<div class="arrow_box">' +
      // '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
      // '</div>'
      //     }
        },
        grid: {
          borderColor: this.borderColor,
          strokeDashArray: 2,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          strokeColor: this.baseColor,
          strokeWidth: 3,
        },
      },
      series: [],
    };
  },
  methods: {
    updatePeriod(period) {
      //if custom date range is selected, displays a modal with date input
      // if (e.target.value === "custom") {
      //   Swal.fire({
      //     text: "Form has been successfully submitted!",
      //     html: `
      //     <h3 class="mb-10">Custom date</h3>
      //     <div class="d-flex flex-column align-items-start mb-5"><p class="mb-0 text-start">From</p><input class="form-control" type="date" id="from-date" required></input></div>
      //     <div class="d-flex flex-column align-items-start"><p class="mb-0">To</p><input class="form-control" type="date" id="to-date" required></input></div>
      //     `,
      //     buttonsStyling: false,
      //     confirmButtonText: "Confirm",
      //     showCancelButton: true,
      //     cancelButtonText: "Cancel",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //       cancelButton: "btn btn-light-primary",
      //     },
      //     focusConfirm: false,
      //     preConfirm: () => {
      //       //checks if dates have been selected and if they're valid
      //       if (
      //         !document.getElementById("from-date").value ||
      //         !document.getElementById("to-date").value ||
      //         document.getElementById("from-date").value >=
      //           document.getElementById("to-date").value
      //       ) {
      //         return false;
      //       } else {
      //         return [
      //           document.getElementById("from-date").value,
      //           document.getElementById("to-date").value,
      //         ];
      //       }
      //     },
      //   }).then((res) => {
      //     this.fromData = res.value[0];
      //     this.toData = res.value[1];
      //     this.fetchData(res.value, this.selectedCountry);
      //     this.selectedPeriod = e.target.value;
      //   });
      // } else {
        if (period === '7d') {
          const from = new Date();
          const pastDate = from.getDate() - 7;
          from.setDate(pastDate);

          this.from = from.toISOString().substr(0, 10);
          this.to = new Date().toISOString().substr(0, 10);
        } else if (period === '30d') {
          const from = new Date();
          const pastDate = from.getDate() - 30;
          from.setDate(pastDate);

          this.from = from.toISOString().substr(0, 10);
          this.to = new Date().toISOString().substr(0, 10);
        } else if (period === '12m') {
          const from = new Date();
          from.setMonth(from.getMonth() - 12);

          this.from = from.toISOString().substr(0, 10);
          this.to = new Date().toISOString().substr(0, 10);
        } else if (period === 'ytd') {
          this.from = new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10);
          this.to = new Date().toISOString().substr(0, 10);
        } else {
          this.from = null;
          this.to = new Date().toISOString().substr(0, 10);
        }

        this.selectedPeriod = period;
        this.fetchData();
      // }
    },
    updateCountry(country) {
      this.selectedCountry = country;
      this.fetchData();
    },
    fetchData() {
      console.log(this.from);
      console.log(this.to);
      console.log(this.selectedCountry);
      this.isLoading = true;

      let endpoint = "";
      if (this.prospectsType == 'hot' || this.prospectsType == 'cold') {
        endpoint = `statistics/${this.prospectsType}-prospects`;
      } else {
        endpoint = `statistics/prospects-ratio`;
      }

      ApiService.query(endpoint, { params: { from: this.from, to: this.to, country: this.selectedCountry } })
        .then(({ data }) => {
          this.series = data;
          this.isLoading = false;
        });
    },
  },
  // Fetches chart data when page loads
  created() {
    this.updatePeriod("7d");
  },
};
</script>

 <style lang="scss" scoped>
</style>